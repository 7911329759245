<template>
  <div class="bodydonate">
    <div class="body-container">
      <Worktrends :worklist="workinfolist" :Statistics="Statisticslist"></Worktrends>
      <Donatetoknow :donatetoknow="donatetoknow"></Donatetoknow>
    </div>
  </div>
</template>

<script>
import Worktrends from './components/worktrends'
import Donatetoknow from './components/donatetoknow'
import apiUrls from '@api'
export default {
  components: {
    Worktrends,
    Donatetoknow
  },
  name: 'Bodydonate',
  data () {
    return {
      workinfolist: [],
	    Statisticslist: {},
      donatetoknow: {}
    }
  },
  created () {
    this.getworklist()
	  this.Statistics()
    this.getbodydonate()
  },
  methods: {
    getworklist() {
      apiUrls.getbodydonate({node: 'ytqgjm_gzdt'}).then(res=>{
		  console.log(res.results.data)
      this.workinfolist= res.results.data
      })
    },
    Statistics() {
      apiUrls.getStatistics({node: 'ytqgjmjxtj'}).then(res=>{
      this.Statisticslist= res.results.data
      })
    },
    getbodydonate () {
      apiUrls.getStatistics({node: 'ytqgjm_jxxz'}).then(res=>{
      this.donatetoknow = res.results.data
      })
    },
  }
}
</script>

<style lang="less" scoped>
.bodydonate {
  background-color: #f5f5f5;
  .body-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
  }
}
</style>
